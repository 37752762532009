import React from 'react';
import homeBg from '@/img/bg/bg-home.jpg';
import logo from "@/img/logo-2.png";

export default function Welcome(props) {
    return (
        <div id="page-container">
            <div id="header" className="header navbar navbar-inverse navbar-fixed-top navbar-expand-lg">
                <div className="container">

                    <div className="navbar-brand">
                        <img src={logo} alt={props.appName}
                             style={{maxWidth: '45px', marginRight: '20px'}}/>
                        <span className="brand-text">
                            {props.appName}
                        </span>
                    </div>


                    <div className="" id="header-navbar">
                        <ul className="nav navbar-nav navbar-right">
                            <li className="nav-item">
                                {props.auth.user ? (
                                    <a href={route('login')} className="nav-link">
                                        Dashboard
                                    </a>
                                ) : (
                                    <a href={route('login')} className="nav-link">
                                        Log in
                                    </a>
                                )

                                }
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div id="home" className="content has-bg home" style={{height: '100vh'}}>
                <div className="content-bg" style={{backgroundImage: `url(${homeBg})`}}/>
                <div className="container home-content">
                    <h1>Welcome to {props.appName}</h1>
                    <h3>Explore your true style.</h3>

                </div>
            </div>
        </div>
    );
}